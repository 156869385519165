import React, { useEffect, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, TableSortLabel, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import getGlobalStyles from './globalStyles';
import { initializeApp } from "firebase/app";
import { useParams } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Link } from 'react-router-dom';
import { visuallyHidden } from '@mui/utils';

const firebaseConfig = {
  apiKey: "AIzaSyABSrvkQzmv_4q8KD26qH8GHB0OnDootr8",
  authDomain: "regnskabsdata.firebaseapp.com",
  projectId: "regnskabsdata",
  storageBucket: "regnskabsdata.appspot.com",
  messagingSenderId: "685976971439",
  appId: "1:685976971439:web:86fb6d10c79982d97d0c5a",
  measurementId: "G-PV0JXDMPJH"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function Zipoverview() {
  
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = React.useState([]);
  const [list, setList] = useState([]);
  let { zip } = useParams();

  const useLocalStyles = makeStyles((theme) => ({
    ...getGlobalStyles(theme),
    table: {
      minWidth: 500,
    }
  }));

  const classes = useLocalStyles();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[0] - b[0];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: 'zip',
      numeric: false,
      disablePadding: false,
      label: 'Postnummer',
    },
    {
      id: 'city',
      numeric: false,
      disablePadding: false,
      label: 'By',
    },
  ]

  const ziplist = [
    {zip: "0800", city: "Høje Taastrup"},
    {zip: "0877", city: "København C"},
    {zip: "0900", city: "København C"},
    {zip: "0910", city: "København C"},
    {zip: "0929", city: "København C"},
    {zip: "0999", city: "København C"},
    {zip: "1001", city: "København K"},
    {zip: "1002", city: "København K"},
    {zip: "1003", city: "København K"},
    {zip: "1004", city: "København K"},
    {zip: "1005", city: "København K"},
    {zip: "1006", city: "København K"},
    {zip: "1007", city: "København K"},
    {zip: "1008", city: "København K"},
    {zip: "1009", city: "København K"},
    {zip: "1010", city: "København K"},
    {zip: "1011", city: "København K"},
    {zip: "1012", city: "København K"},
    {zip: "1013", city: "København K"},
    {zip: "1014", city: "København K"},
    {zip: "1015", city: "København K"},
    {zip: "1016", city: "København K"},
    {zip: "1017", city: "København K"},
    {zip: "1018", city: "København K"},
    {zip: "1019", city: "København K"},
    {zip: "1020", city: "København K"},
    {zip: "1021", city: "København K"},
    {zip: "1022", city: "København K"},
    {zip: "1023", city: "København K"},
    {zip: "1024", city: "København K"},
    {zip: "1025", city: "København K"},
    {zip: "1026", city: "København K"},
    {zip: "1045", city: "København K"},
    {zip: "1050", city: "København K"},
    {zip: "1051", city: "København K"},
    {zip: "1052", city: "København K"},
    {zip: "1053", city: "København K"},
    {zip: "1054", city: "København K"},
    {zip: "1055", city: "København K"},
    {zip: "1055", city: "København K"},
    {zip: "1056", city: "København K"},
    {zip: "1057", city: "København K"},
    {zip: "1058", city: "København K"},
    {zip: "1059", city: "København K"},
    {zip: "1060", city: "København K"},
    {zip: "1061", city: "København K"},
    {zip: "1062", city: "København K"},
    {zip: "1063", city: "København K"},
    {zip: "1064", city: "København K"},
    {zip: "1065", city: "København K"},
    {zip: "1066", city: "København K"},
    {zip: "1067", city: "København K"},
    {zip: "1068", city: "København K"},
    {zip: "1069", city: "København K"},
    {zip: "1070", city: "København K"},
    {zip: "1071", city: "København K"},
    {zip: "1072", city: "København K"},
    {zip: "1073", city: "København K"},
    {zip: "1074", city: "København K"},
    {zip: "1092", city: "København K"},
    {zip: "1093", city: "København K"},
    {zip: "1095", city: "København K"},
    {zip: "1098", city: "København K"},
    {zip: "1100", city: "København K"},
    {zip: "1101", city: "København K"},
    {zip: "1102", city: "København K"},
    {zip: "1103", city: "København K"},
    {zip: "1104", city: "København K"},
    {zip: "1105", city: "København K"},
    {zip: "1106", city: "København K"},
    {zip: "1107", city: "København K"},
    {zip: "1110", city: "København K"},
    {zip: "1111", city: "København K"},
    {zip: "1112", city: "København K"},
    {zip: "1113", city: "København K"},
    {zip: "1114", city: "København K"},
    {zip: "1115", city: "København K"},
    {zip: "1116", city: "København K"},
    {zip: "1117", city: "København K"},
    {zip: "1118", city: "København K"},
    {zip: "1119", city: "København K"},
    {zip: "1120", city: "København K"},
    {zip: "1121", city: "København K"},
    {zip: "1122", city: "København K"},
    {zip: "1123", city: "København K"},
    {zip: "1124", city: "København K"},
    {zip: "1125", city: "København K"},
    {zip: "1126", city: "København K"},
    {zip: "1127", city: "København K"},
    {zip: "1128", city: "København K"},
    {zip: "1129", city: "København K"},
    {zip: "1130", city: "København K"},
    {zip: "1131", city: "København K"},
    {zip: "1140", city: "København K"},
    {zip: "1147", city: "København K"},
    {zip: "1148", city: "København K"},
    {zip: "1150", city: "København K"},
    {zip: "1151", city: "København K"},
    {zip: "1152", city: "København K"},
    {zip: "1153", city: "København K"},
    {zip: "1154", city: "København K"},
    {zip: "1155", city: "København K"},
    {zip: "1156", city: "København K"},
    {zip: "1157", city: "København K"},
    {zip: "1158", city: "København K"},
    {zip: "1159", city: "København K"},
    {zip: "1160", city: "København K"},
    {zip: "1161", city: "København K"},
    {zip: "1162", city: "København K"},
    {zip: "1164", city: "København K"},
    {zip: "1165", city: "København K"},
    {zip: "1165", city: "København K"},
    {zip: "1166", city: "København K"},
    {zip: "1167", city: "København K"},
    {zip: "1168", city: "København K"},
    {zip: "1169", city: "København K"},
    {zip: "1170", city: "København K"},
    {zip: "1171", city: "København K"},
    {zip: "1172", city: "København K"},
    {zip: "1173", city: "København K"},
    {zip: "1174", city: "København K"},
    {zip: "1175", city: "København K"},
    {zip: "1200", city: "København K"},
    {zip: "1201", city: "København K"},
    {zip: "1202", city: "København K"},
    {zip: "1203", city: "København K"},
    {zip: "1204", city: "København K"},
    {zip: "1205", city: "København K"},
    {zip: "1206", city: "København K"},
    {zip: "1207", city: "København K"},
    {zip: "1208", city: "København K"},
    {zip: "1209", city: "København K"},
    {zip: "1210", city: "København K"},
    {zip: "1211", city: "København K"},
    {zip: "1212", city: "København K"},
    {zip: "1213", city: "København K"},
    {zip: "1214", city: "København K"},
    {zip: "1215", city: "København K"},
    {zip: "1216", city: "København K"},
    {zip: "1217", city: "København K"},
    {zip: "1218", city: "København K"},
    {zip: "1218", city: "København K"},
    {zip: "1218", city: "København K"},
    {zip: "1218", city: "København K"},
    {zip: "1218", city: "København K"},
    {zip: "1218", city: "København K"},
    {zip: "1219", city: "København K"},
    {zip: "1220", city: "København K"},
    {zip: "1221", city: "København K"},
    {zip: "1240", city: "København K"},
    {zip: "1250", city: "København K"},
    {zip: "1251", city: "København K"},
    {zip: "1252", city: "København K"},
    {zip: "1253", city: "København K"},
    {zip: "1254", city: "København K"},
    {zip: "1255", city: "København K"},
    {zip: "1256", city: "København K"},
    {zip: "1257", city: "København K"},
    {zip: "1259", city: "København K"},
    {zip: "1259", city: "København K"},
    {zip: "1260", city: "København K"},
    {zip: "1261", city: "København K"},
    {zip: "1263", city: "København K"},
    {zip: "1263", city: "København K"},
    {zip: "1264", city: "København K"},
    {zip: "1265", city: "København K"},
    {zip: "1266", city: "København K"},
    {zip: "1267", city: "København K"},
    {zip: "1268", city: "København K"},
    {zip: "1270", city: "København K"},
    {zip: "1271", city: "København K"},
    {zip: "1300", city: "København K"},
    {zip: "1301", city: "København K"},
    {zip: "1302", city: "København K"},
    {zip: "1303", city: "København K"},
    {zip: "1304", city: "København K"},
    {zip: "1306", city: "København K"},
    {zip: "1307", city: "København K"},
    {zip: "1307", city: "København K"},
    {zip: "1308", city: "København K"},
    {zip: "1309", city: "København K"},
    {zip: "1310", city: "København K"},
    {zip: "1311", city: "København K"},
    {zip: "1312", city: "København K"},
    {zip: "1313", city: "København K"},
    {zip: "1314", city: "København K"},
    {zip: "1315", city: "København K"},
    {zip: "1316", city: "København K"},
    {zip: "1317", city: "København K"},
    {zip: "1318", city: "København K"},
    {zip: "1319", city: "København K"},
    {zip: "1320", city: "København K"},
    {zip: "1321", city: "København K"},
    {zip: "1322", city: "København K"},
    {zip: "1323", city: "København K"},
    {zip: "1324", city: "København K"},
    {zip: "1325", city: "København K"},
    {zip: "1326", city: "København K"},
    {zip: "1327", city: "København K"},
    {zip: "1328", city: "København K"},
    {zip: "1329", city: "København K"},
    {zip: "1350", city: "København K"},
    {zip: "1352", city: "København K"},
    {zip: "1353", city: "København K"},
    {zip: "1354", city: "København K"},
    {zip: "1355", city: "København K"},
    {zip: "1356", city: "København K"},
    {zip: "1357", city: "København K"},
    {zip: "1358", city: "København K"},
    {zip: "1359", city: "København K"},
    {zip: "1359", city: "København K"},
    {zip: "1360", city: "København K"},
    {zip: "1361", city: "København K"},
    {zip: "1361", city: "København K"},
    {zip: "1362", city: "København K"},
    {zip: "1363", city: "København K"},
    {zip: "1364", city: "København K"},
    {zip: "1365", city: "København K"},
    {zip: "1366", city: "København K"},
    {zip: "1367", city: "København K"},
    {zip: "1368", city: "København K"},
    {zip: "1369", city: "København K"},
    {zip: "1370", city: "København K"},
    {zip: "1371", city: "København K"},
    {zip: "1400", city: "København K"},
    {zip: "1400", city: "København K"},
    {zip: "1401", city: "København K"},
    {zip: "1402", city: "København K"},
    {zip: "1402", city: "København K"},
    {zip: "1402", city: "København K"},
    {zip: "1402", city: "København K"},
    {zip: "1402", city: "København K"},
    {zip: "1403", city: "København K"},
    {zip: "1406", city: "København K"},
    {zip: "1407", city: "København K"},
    {zip: "1408", city: "København K"},
    {zip: "1409", city: "København K"},
    {zip: "1410", city: "København K"},
    {zip: "1411", city: "København K"},
    {zip: "1411", city: "København K"},
    {zip: "1412", city: "København K"},
    {zip: "1413", city: "København K"},
    {zip: "1414", city: "København K"},
    {zip: "1415", city: "København K"},
    {zip: "1416", city: "København K"},
    {zip: "1417", city: "København K"},
    {zip: "1418", city: "København K"},
    {zip: "1419", city: "København K"},
    {zip: "1420", city: "København K"},
    {zip: "1421", city: "København K"},
    {zip: "1422", city: "København K"},
    {zip: "1423", city: "København K"},
    {zip: "1424", city: "København K"},
    {zip: "1425", city: "København K"},
    {zip: "1426", city: "København K"},
    {zip: "1427", city: "København K"},
    {zip: "1428", city: "København K"},
    {zip: "1429", city: "København K"},
    {zip: "1430", city: "København K"},
    {zip: "1432", city: "København K"},
    {zip: "1432", city: "København K"},
    {zip: "1432", city: "København K"},
    {zip: "1433", city: "København K"},
    {zip: "1433", city: "København K"},
    {zip: "1433", city: "København K"},
    {zip: "1433", city: "København K"},
    {zip: "1433", city: "København K"},
    {zip: "1433", city: "København K"},
    {zip: "1433", city: "København K"},
    {zip: "1434", city: "København K"},
    {zip: "1434", city: "København K"},
    {zip: "1435", city: "København K"},
    {zip: "1436", city: "København K"},
    {zip: "1436", city: "København K"},
    {zip: "1436", city: "København K"},
    {zip: "1436", city: "København K"},
    {zip: "1436", city: "København K"},
    {zip: "1436", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1437", city: "København K"},
    {zip: "1438", city: "København K"},
    {zip: "1438", city: "København K"},
    {zip: "1438", city: "København K"},
    {zip: "1438", city: "København K"},
    {zip: "1438", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1439", city: "København K"},
    {zip: "1440", city: "København K"},
    {zip: "1440", city: "København K"},
    {zip: "1440", city: "København K"},
    {zip: "1440", city: "København K"},
    {zip: "1440", city: "København K"},
    {zip: "1440", city: "København K"},
    {zip: "1440", city: "København K"},
    {zip: "1440", city: "København K"},
    {zip: "1440", city: "København K"},
    {zip: "1440", city: "København K"},
    {zip: "1440", city: "København K"},
    {zip: "1441", city: "København K"},
    {zip: "1441", city: "København K"},
    {zip: "1441", city: "København K"},
    {zip: "1448", city: "København K"},
    {zip: "1450", city: "København K"},
    {zip: "1451", city: "København K"},
    {zip: "1452", city: "København K"},
    {zip: "1453", city: "København K"},
    {zip: "1454", city: "København K"},
    {zip: "1455", city: "København K"},
    {zip: "1456", city: "København K"},
    {zip: "1457", city: "København K"},
    {zip: "1458", city: "København K"},
    {zip: "1459", city: "København K"},
    {zip: "1460", city: "København K"},
    {zip: "1461", city: "København K"},
    {zip: "1462", city: "København K"},
    {zip: "1463", city: "København K"},
    {zip: "1464", city: "København K"},
    {zip: "1465", city: "København K"},
    {zip: "1466", city: "København K"},
    {zip: "1467", city: "København K"},
    {zip: "1468", city: "København K"},
    {zip: "1470", city: "København K"},
    {zip: "1471", city: "København K"},
    {zip: "1472", city: "København K"},
    {zip: "1473", city: "København K"},
    {zip: "1500", city: "København V"},
    {zip: "1501", city: "København V"},
    {zip: "1502", city: "København V"},
    {zip: "1503", city: "København V"},
    {zip: "1504", city: "København V"},
    {zip: "1505", city: "København V"},
    {zip: "1506", city: "København V"},
    {zip: "1507", city: "København V"},
    {zip: "1508", city: "København V"},
    {zip: "1509", city: "København V"},
    {zip: "1510", city: "København V"},
    {zip: "1532", city: "København V"},
    {zip: "1533", city: "København V"},
    {zip: "1550", city: "København V"},
    {zip: "1550", city: "København V"},
    {zip: "1551", city: "København V"},
    {zip: "1552", city: "København V"},
    {zip: "1553", city: "København V"},
    {zip: "1553", city: "København V"},
    {zip: "1554", city: "København V"},
    {zip: "1555", city: "København V"},
    {zip: "1556", city: "København V"},
    {zip: "1557", city: "København V"},
    {zip: "1558", city: "København V"},
    {zip: "1559", city: "København V"},
    {zip: "1560", city: "København V"},
    {zip: "1561", city: "København V"},
    {zip: "1561", city: "København V"},
    {zip: "1562", city: "København V"},
    {zip: "1563", city: "København V"},
    {zip: "1564", city: "København V"},
    {zip: "1567", city: "København V"},
    {zip: "1568", city: "København V"},
    {zip: "1569", city: "København V"},
    {zip: "1570", city: "København V"},
    {zip: "1570", city: "København V"},
    {zip: "1571", city: "København V"},
    {zip: "1572", city: "København V"},
    {zip: "1573", city: "København V"},
    {zip: "1574", city: "København V"},
    {zip: "1575", city: "København V"},
    {zip: "1576", city: "København V"},
    {zip: "1577", city: "København V"},
    {zip: "1577", city: "København V"},
    {zip: "1577", city: "København V"},
    {zip: "1592", city: "København V"},
    {zip: "1599", city: "København V"},
    {zip: "1600", city: "København V"},
    {zip: "1601", city: "København V"},
    {zip: "1602", city: "København V"},
    {zip: "1603", city: "København V"},
    {zip: "1604", city: "København V"},
    {zip: "1605", city: "København V"},
    {zip: "1606", city: "København V"},
    {zip: "1607", city: "København V"},
    {zip: "1608", city: "København V"},
    {zip: "1609", city: "København V"},
    {zip: "1610", city: "København V"},
    {zip: "1611", city: "København V"},
    {zip: "1612", city: "København V"},
    {zip: "1613", city: "København V"},
    {zip: "1614", city: "København V"},
    {zip: "1615", city: "København V"},
    {zip: "1616", city: "København V"},
    {zip: "1617", city: "København V"},
    {zip: "1618", city: "København V"},
    {zip: "1619", city: "København V"},
    {zip: "1620", city: "København V"},
    {zip: "1620", city: "København V"},
    {zip: "1621", city: "København V"},
    {zip: "1622", city: "København V"},
    {zip: "1623", city: "København V"},
    {zip: "1624", city: "København V"},
    {zip: "1630", city: "København V"},
    {zip: "1631", city: "København V"},
    {zip: "1632", city: "København V"},
    {zip: "1633", city: "København V"},
    {zip: "1634", city: "København V"},
    {zip: "1635", city: "København V"},
    {zip: "1650", city: "København V"},
    {zip: "1651", city: "København V"},
    {zip: "1652", city: "København V"},
    {zip: "1653", city: "København V"},
    {zip: "1654", city: "København V"},
    {zip: "1655", city: "København V"},
    {zip: "1656", city: "København V"},
    {zip: "1657", city: "København V"},
    {zip: "1658", city: "København V"},
    {zip: "1659", city: "København V"},
    {zip: "1660", city: "København V"},
    {zip: "1660", city: "København V"},
    {zip: "1661", city: "København V"},
    {zip: "1662", city: "København V"},
    {zip: "1663", city: "København V"},
    {zip: "1664", city: "København V"},
    {zip: "1665", city: "København V"},
    {zip: "1666", city: "København V"},
    {zip: "1667", city: "København V"},
    {zip: "1668", city: "København V"},
    {zip: "1669", city: "København V"},
    {zip: "1670", city: "København V"},
    {zip: "1671", city: "København V"},
    {zip: "1671", city: "København V"},
    {zip: "1672", city: "København V"},
    {zip: "1673", city: "København V"},
    {zip: "1674", city: "København V"},
    {zip: "1675", city: "København V"},
    {zip: "1676", city: "København V"},
    {zip: "1677", city: "København V"},
    {zip: "1699", city: "København V"},
    {zip: "1700", city: "København V"},
    {zip: "1701", city: "København V"},
    {zip: "1702", city: "København V"},
    {zip: "1703", city: "København V"},
    {zip: "1704", city: "København V"},
    {zip: "1705", city: "København V"},
    {zip: "1706", city: "København V"},
    {zip: "1707", city: "København V"},
    {zip: "1708", city: "København V"},
    {zip: "1709", city: "København V"},
    {zip: "1710", city: "København V"},
    {zip: "1711", city: "København V"},
    {zip: "1711", city: "København V"},
    {zip: "1712", city: "København V"},
    {zip: "1714", city: "København V"},
    {zip: "1715", city: "København V"},
    {zip: "1716", city: "København V"},
    {zip: "1717", city: "København V"},
    {zip: "1718", city: "København V"},
    {zip: "1719", city: "København V"},
    {zip: "1720", city: "København V"},
    {zip: "1721", city: "København V"},
    {zip: "1722", city: "København V"},
    {zip: "1723", city: "København V"},
    {zip: "1724", city: "København V"},
    {zip: "1725", city: "København V"},
    {zip: "1726", city: "København V"},
    {zip: "1727", city: "København V"},
    {zip: "1728", city: "København V"},
    {zip: "1729", city: "København V"},
    {zip: "1730", city: "København V"},
    {zip: "1731", city: "København V"},
    {zip: "1732", city: "København V"},
    {zip: "1733", city: "København V"},
    {zip: "1734", city: "København V"},
    {zip: "1735", city: "København V"},
    {zip: "1736", city: "København V"},
    {zip: "1737", city: "København V"},
    {zip: "1738", city: "København V"},
    {zip: "1739", city: "København V"},
    {zip: "1749", city: "København V"},
    {zip: "1750", city: "København V"},
    {zip: "1751", city: "København V"},
    {zip: "1752", city: "København V"},
    {zip: "1753", city: "København V"},
    {zip: "1754", city: "København V"},
    {zip: "1755", city: "København V"},
    {zip: "1756", city: "København V"},
    {zip: "1757", city: "København V"},
    {zip: "1758", city: "København V"},
    {zip: "1759", city: "København V"},
    {zip: "1760", city: "København V"},
    {zip: "1761", city: "København V"},
    {zip: "1762", city: "København V"},
    {zip: "1763", city: "København V"},
    {zip: "1764", city: "København V"},
    {zip: "1765", city: "København V"},
    {zip: "1766", city: "København V"},
    {zip: "1770", city: "København V"},
    {zip: "1771", city: "København V"},
    {zip: "1772", city: "København V"},
    {zip: "1773", city: "København V"},
    {zip: "1774", city: "København V"},
    {zip: "1775", city: "København V"},
    {zip: "1777", city: "København V"},
    {zip: "1780", city: "København V"},
    {zip: "1782", city: "København V"},
    {zip: "1785", city: "København V"},
    {zip: "1786", city: "København V"},
    {zip: "1787", city: "København V"},
    {zip: "1790", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1799", city: "København V"},
    {zip: "1800", city: "Frederiksberg C"},
    {zip: "1801", city: "Frederiksberg C"},
    {zip: "1802", city: "Frederiksberg C"},
    {zip: "1803", city: "Frederiksberg C"},
    {zip: "1804", city: "Frederiksberg C"},
    {zip: "1805", city: "Frederiksberg C"},
    {zip: "1806", city: "Frederiksberg C"},
    {zip: "1807", city: "Frederiksberg C"},
    {zip: "1808", city: "Frederiksberg C"},
    {zip: "1809", city: "Frederiksberg C"},
    {zip: "1810", city: "Frederiksberg C"},
    {zip: "1811", city: "Frederiksberg C"},
    {zip: "1812", city: "Frederiksberg C"},
    {zip: "1813", city: "Frederiksberg C"},
    {zip: "1814", city: "Frederiksberg C"},
    {zip: "1815", city: "Frederiksberg C"},
    {zip: "1816", city: "Frederiksberg C"},
    {zip: "1817", city: "Frederiksberg C"},
    {zip: "1818", city: "Frederiksberg C"},
    {zip: "1819", city: "Frederiksberg C"},
    {zip: "1820", city: "Frederiksberg C"},
    {zip: "1822", city: "Frederiksberg C"},
    {zip: "1823", city: "Frederiksberg C"},
    {zip: "1824", city: "Frederiksberg C"},
    {zip: "1825", city: "Frederiksberg C"},
    {zip: "1826", city: "Frederiksberg C"},
    {zip: "1827", city: "Frederiksberg C"},
    {zip: "1828", city: "Frederiksberg C"},
    {zip: "1829", city: "Frederiksberg C"},
    {zip: "1835", city: "Frederiksberg C"},
    {zip: "1850", city: "Frederiksberg C"},
    {zip: "1851", city: "Frederiksberg C"},
    {zip: "1852", city: "Frederiksberg C"},
    {zip: "1853", city: "Frederiksberg C"},
    {zip: "1854", city: "Frederiksberg C"},
    {zip: "1855", city: "Frederiksberg C"},
    {zip: "1856", city: "Frederiksberg C"},
    {zip: "1857", city: "Frederiksberg C"},
    {zip: "1860", city: "Frederiksberg C"},
    {zip: "1861", city: "Frederiksberg C"},
    {zip: "1862", city: "Frederiksberg C"},
    {zip: "1863", city: "Frederiksberg C"},
    {zip: "1864", city: "Frederiksberg C"},
    {zip: "1865", city: "Frederiksberg C"},
    {zip: "1866", city: "Frederiksberg C"},
    {zip: "1867", city: "Frederiksberg C"},
    {zip: "1868", city: "Frederiksberg C"},
    {zip: "1870", city: "Frederiksberg C"},
    {zip: "1871", city: "Frederiksberg C"},
    {zip: "1872", city: "Frederiksberg C"},
    {zip: "1873", city: "Frederiksberg C"},
    {zip: "1874", city: "Frederiksberg C"},
    {zip: "1875", city: "Frederiksberg C"},
    {zip: "1876", city: "Frederiksberg C"},
    {zip: "1877", city: "Frederiksberg C"},
    {zip: "1878", city: "Frederiksberg C"},
    {zip: "1879", city: "Frederiksberg C"},
    {zip: "1900", city: "Frederiksberg C"},
    {zip: "1901", city: "Frederiksberg C"},
    {zip: "1902", city: "Frederiksberg C"},
    {zip: "1903", city: "Frederiksberg C"},
    {zip: "1904", city: "Frederiksberg C"},
    {zip: "1905", city: "Frederiksberg C"},
    {zip: "1906", city: "Frederiksberg C"},
    {zip: "1908", city: "Frederiksberg C"},
    {zip: "1909", city: "Frederiksberg C"},
    {zip: "1910", city: "Frederiksberg C"},
    {zip: "1911", city: "Frederiksberg C"},
    {zip: "1912", city: "Frederiksberg C"},
    {zip: "1913", city: "Frederiksberg C"},
    {zip: "1914", city: "Frederiksberg C"},
    {zip: "1915", city: "Frederiksberg C"},
    {zip: "1916", city: "Frederiksberg C"},
    {zip: "1917", city: "Frederiksberg C"},
    {zip: "1920", city: "Frederiksberg C"},
    {zip: "1921", city: "Frederiksberg C"},
    {zip: "1922", city: "Frederiksberg C"},
    {zip: "1923", city: "Frederiksberg C"},
    {zip: "1924", city: "Frederiksberg C"},
    {zip: "1925", city: "Frederiksberg C"},
    {zip: "1926", city: "Frederiksberg C"},
    {zip: "1927", city: "Frederiksberg C"},
    {zip: "1928", city: "Frederiksberg C"},
    {zip: "1931", city: "Frederiksberg C"},
    {zip: "1950", city: "Frederiksberg C"},
    {zip: "1951", city: "Frederiksberg C"},
    {zip: "1952", city: "Frederiksberg C"},
    {zip: "1953", city: "Frederiksberg C"},
    {zip: "1954", city: "Frederiksberg C"},
    {zip: "1955", city: "Frederiksberg C"},
    {zip: "1956", city: "Frederiksberg C"},
    {zip: "1957", city: "Frederiksberg C"},
    {zip: "1958", city: "Frederiksberg C"},
    {zip: "1959", city: "Frederiksberg C"},
    {zip: "1960", city: "Frederiksberg C"},
    {zip: "1961", city: "Frederiksberg C"},
    {zip: "1962", city: "Frederiksberg C"},
    {zip: "1963", city: "Frederiksberg C"},
    {zip: "1964", city: "Frederiksberg C"},
    {zip: "1965", city: "Frederiksberg C"},
    {zip: "1966", city: "Frederiksberg C"},
    {zip: "1967", city: "Frederiksberg C"},
    {zip: "1970", city: "Frederiksberg C"},
    {zip: "1971", city: "Frederiksberg C"},
    {zip: "1972", city: "Frederiksberg C"},
    {zip: "1973", city: "Frederiksberg C"},
    {zip: "1974", city: "Frederiksberg C"},
    {zip: "2000", city: "Frederiksberg"},
    {zip: "2100", city: "København Ø"},
    {zip: "2150", city: "Nordhavn"},
    {zip: "2200", city: "København N"},
    {zip: "2300", city: "København S"},
    {zip: "2400", city: "København NV"},
    {zip: "2412", city: "Grønland"},
    {zip: "2450", city: "København SV"},
    {zip: "2500", city: "Valby"},
    {zip: "2600", city: "Glostrup"},
    {zip: "2605", city: "Brøndby"},
    {zip: "2610", city: "Rødovre"},
    {zip: "2620", city: "Albertslund"},
    {zip: "2625", city: "Vallensbæk"},
    {zip: "2630", city: "Taastrup"},
    {zip: "2635", city: "Ishøj"},
    {zip: "2640", city: "Hedehusene"},
    {zip: "2644", city: "Spejdernes Lejr 2022"},
    {zip: "2650", city: "Hvidovre"},
    {zip: "2660", city: "Brøndby Strand"},
    {zip: "2665", city: "Vallensbæk Strand"},
    {zip: "2670", city: "Greve"},
    {zip: "2680", city: "Solrød Strand"},
    {zip: "2690", city: "Karlslunde"},
    {zip: "2700", city: "Brønshøj"},
    {zip: "2720", city: "Vanløse"},
    {zip: "2730", city: "Herlev"},
    {zip: "2740", city: "Skovlunde"},
    {zip: "2750", city: "Ballerup"},
    {zip: "2760", city: "Måløv"},
    {zip: "2765", city: "Smørum"},
    {zip: "2770", city: "Kastrup"},
    {zip: "2791", city: "Dragør"},
    {zip: "2800", city: "Kongens Lyngby"},
    {zip: "2820", city: "Gentofte"},
    {zip: "2830", city: "Virum"},
    {zip: "2840", city: "Holte"},
    {zip: "2850", city: "Nærum"},
    {zip: "2860", city: "Søborg"},
    {zip: "2870", city: "Dyssegård"},
    {zip: "2880", city: "Bagsværd"},
    {zip: "2900", city: "Hellerup"},
    {zip: "2920", city: "Charlottenlund"},
    {zip: "2930", city: "Klampenborg"},
    {zip: "2942", city: "Skodsborg"},
    {zip: "2950", city: "Vedbæk"},
    {zip: "2960", city: "Rungsted Kyst"},
    {zip: "2970", city: "Hørsholm"},
    {zip: "2980", city: "Kokkedal"},
    {zip: "2990", city: "Nivå"},
    {zip: "3000", city: "Helsingør"},
    {zip: "3050", city: "Humlebæk"},
    {zip: "3060", city: "Espergærde"},
    {zip: "3070", city: "Snekkersten"},
    {zip: "3080", city: "Tikøb"},
    {zip: "3100", city: "Hornbæk"},
    {zip: "3120", city: "Dronningmølle"},
    {zip: "3140", city: "Ålsgårde"},
    {zip: "3150", city: "Hellebæk"},
    {zip: "3200", city: "Helsinge"},
    {zip: "3210", city: "Vejby"},
    {zip: "3220", city: "Tisvildeleje"},
    {zip: "3230", city: "Græsted"},
    {zip: "3250", city: "Gilleleje"},
    {zip: "3300", city: "Frederiksværk"},
    {zip: "3310", city: "Ølsted"},
    {zip: "3320", city: "Skævinge"},
    {zip: "3330", city: "Gørløse"},
    {zip: "3360", city: "Liseleje"},
    {zip: "3370", city: "Melby"},
    {zip: "3390", city: "Hundested"},
    {zip: "3400", city: "Hillerød"},
    {zip: "3450", city: "Allerød"},
    {zip: "3460", city: "Birkerød"},
    {zip: "3480", city: "Fredensborg"},
    {zip: "3490", city: "Kvistgård"},
    {zip: "3500", city: "Værløse"},
    {zip: "3520", city: "Farum"},
    {zip: "3540", city: "Lynge"},
    {zip: "3550", city: "Slangerup"},
    {zip: "3600", city: "Frederikssund"},
    {zip: "3630", city: "Jægerspris"},
    {zip: "3650", city: "Ølstykke"},
    {zip: "3660", city: "Stenløse"},
    {zip: "3670", city: "Veksø Sjælland"},
    {zip: "3700", city: "Rønne"},
    {zip: "3720", city: "Aakirkeby"},
    {zip: "3730", city: "Nexø"},
    {zip: "3740", city: "Svaneke"},
    {zip: "3751", city: "Østermarie"},
    {zip: "3760", city: "Gudhjem"},
    {zip: "3770", city: "Allinge"},
    {zip: "3782", city: "Klemensker"},
    {zip: "3790", city: "Hasle"},
    {zip: "4000", city: "Roskilde"},
    {zip: "4030", city: "Tune"},
    {zip: "4040", city: "Jyllinge"},
    {zip: "4050", city: "Skibby"},
    {zip: "4060", city: "Kirke Såby"},
    {zip: "4070", city: "Kirke Hyllinge"},
    {zip: "4100", city: "Ringsted"},
    {zip: "4129", city: "Ringsted"},
    {zip: "4130", city: "Viby Sjælland"},
    {zip: "4140", city: "Borup"},
    {zip: "4160", city: "Herlufmagle"},
    {zip: "4171", city: "Glumsø"},
    {zip: "4173", city: "Fjenneslev"},
    {zip: "4174", city: "Jystrup Midtsj"},
    {zip: "4180", city: "Sorø"},
    {zip: "4190", city: "Munke Bjergby"},
    {zip: "4200", city: "Slagelse"},
    {zip: "4220", city: "Korsør"},
    {zip: "4230", city: "Skælskør"},
    {zip: "4241", city: "Vemmelev"},
    {zip: "4242", city: "Boeslunde"},
    {zip: "4243", city: "Rude"},
    {zip: "4244", city: "Agersø"},
    {zip: "4245", city: "Omø"},
    {zip: "4250", city: "Fuglebjerg"},
    {zip: "4261", city: "Dalmose"},
    {zip: "4262", city: "Sandved"},
    {zip: "4270", city: "Høng"},
    {zip: "4281", city: "Gørlev"},
    {zip: "4291", city: "Ruds Vedby"},
    {zip: "4293", city: "Dianalund"},
    {zip: "4295", city: "Stenlille"},
    {zip: "4296", city: "Nyrup"},
    {zip: "4300", city: "Holbæk"},
    {zip: "4305", city: "Orø"},
    {zip: "4320", city: "Lejre"},
    {zip: "4330", city: "Hvalsø"},
    {zip: "4340", city: "Tølløse"},
    {zip: "4350", city: "Ugerløse"},
    {zip: "4360", city: "Kirke Eskilstrup"},
    {zip: "4370", city: "Store Merløse"},
    {zip: "4390", city: "Vipperød"},
    {zip: "4400", city: "Kalundborg"},
    {zip: "4420", city: "Regstrup"},
    {zip: "4440", city: "Mørkøv"},
    {zip: "4450", city: "Jyderup"},
    {zip: "4460", city: "Snertinge"},
    {zip: "4470", city: "Svebølle"},
    {zip: "4480", city: "Store Fuglede"},
    {zip: "4490", city: "Jerslev Sjælland"},
    {zip: "4500", city: "Nykøbing Sj"},
    {zip: "4520", city: "Svinninge"},
    {zip: "4532", city: "Gislinge"},
    {zip: "4534", city: "Hørve"},
    {zip: "4540", city: "Fårevejle"},
    {zip: "4550", city: "Asnæs"},
    {zip: "4560", city: "Vig"},
    {zip: "4571", city: "Grevinge"},
    {zip: "4572", city: "Nørre Asmindrup"},
    {zip: "4573", city: "Højby"},
    {zip: "4581", city: "Rørvig"},
    {zip: "4583", city: "Sjællands Odde"},
    {zip: "4591", city: "Føllenslev"},
    {zip: "4592", city: "Sejerø"},
    {zip: "4593", city: "Eskebjerg"},
    {zip: "4600", city: "Køge"},
    {zip: "4621", city: "Gadstrup"},
    {zip: "4622", city: "Havdrup"},
    {zip: "4623", city: "Lille Skensved"},
    {zip: "4632", city: "Bjæverskov"},
    {zip: "4640", city: "Faxe"},
    {zip: "4652", city: "Hårlev"},
    {zip: "4653", city: "Karise"},
    {zip: "4654", city: "Faxe Ladeplads"},
    {zip: "4660", city: "Store Heddinge"},
    {zip: "4671", city: "Strøby"},
    {zip: "4672", city: "Klippinge"},
    {zip: "4673", city: "Rødvig Stevns"},
    {zip: "4681", city: "Herfølge"},
    {zip: "4682", city: "Tureby"},
    {zip: "4683", city: "Rønnede"},
    {zip: "4684", city: "Holmegaard"},
    {zip: "4690", city: "Haslev"},
    {zip: "4700", city: "Næstved"},
    {zip: "4720", city: "Præstø"},
    {zip: "4733", city: "Tappernøje"},
    {zip: "4735", city: "Mern"},
    {zip: "4736", city: "Karrebæksminde"},
    {zip: "4750", city: "Lundby"},
    {zip: "4760", city: "Vordingborg"},
    {zip: "4771", city: "Kalvehave"},
    {zip: "4772", city: "Langebæk"},
    {zip: "4773", city: "Stensved"},
    {zip: "4780", city: "Stege"},
    {zip: "4791", city: "Borre"},
    {zip: "4792", city: "Askeby"},
    {zip: "4793", city: "Bogø By"},
    {zip: "4800", city: "Nykøbing F"},
    {zip: "4840", city: "Nørre Alslev"},
    {zip: "4850", city: "Stubbekøbing"},
    {zip: "4862", city: "Guldborg"},
    {zip: "4863", city: "Eskilstrup"},
    {zip: "4871", city: "Horbelev"},
    {zip: "4872", city: "Idestrup"},
    {zip: "4873", city: "Væggerløse"},
    {zip: "4874", city: "Gedser"},
    {zip: "4880", city: "Nysted"},
    {zip: "4891", city: "Toreby L"},
    {zip: "4892", city: "Kettinge"},
    {zip: "4894", city: "Øster Ulslev"},
    {zip: "4895", city: "Errindlev"},
    {zip: "4900", city: "Nakskov"},
    {zip: "4912", city: "Harpelunde"},
    {zip: "4913", city: "Horslunde"},
    {zip: "4920", city: "Søllested"},
    {zip: "4930", city: "Maribo"},
    {zip: "4941", city: "Bandholm"},
    {zip: "4942", city: "Askø og Lilleø"},
    {zip: "4943", city: "Torrig L"},
    {zip: "4944", city: "Fejø"},
    {zip: "4945", city: "Femø"},
    {zip: "4951", city: "Nørreballe"},
    {zip: "4952", city: "Stokkemarke"},
    {zip: "4953", city: "Vesterborg"},
    {zip: "4960", city: "Holeby"},
    {zip: "4970", city: "Rødby"},
    {zip: "4983", city: "Dannemare"},
    {zip: "4990", city: "Sakskøbing"},
    {zip: "4992", city: "Midtsjælland USF P"},
    {zip: "5000", city: "Odense C"},
    {zip: "5029", city: "Odense C"},
    {zip: "5100", city: "Odense C"},
    {zip: "5200", city: "Odense V"},
    {zip: "5210", city: "Odense NV"},
    {zip: "5220", city: "Odense SØ"},
    {zip: "5230", city: "Odense M"},
    {zip: "5240", city: "Odense NØ"},
    {zip: "5250", city: "Odense SV"},
    {zip: "5260", city: "Odense S"},
    {zip: "5270", city: "Odense N"},
    {zip: "5290", city: "Marslev"},
    {zip: "5300", city: "Kerteminde"},
    {zip: "5320", city: "Agedrup"},
    {zip: "5330", city: "Munkebo"},
    {zip: "5350", city: "Rynkeby"},
    {zip: "5370", city: "Mesinge"},
    {zip: "5380", city: "Dalby"},
    {zip: "5390", city: "Martofte"},
    {zip: "5400", city: "Bogense"},
    {zip: "5450", city: "Otterup"},
    {zip: "5462", city: "Morud"},
    {zip: "5463", city: "Harndrup"},
    {zip: "5464", city: "Brenderup Fyn"},
    {zip: "5466", city: "Asperup"},
    {zip: "5471", city: "Søndersø"},
    {zip: "5474", city: "Veflinge"},
    {zip: "5485", city: "Skamby"},
    {zip: "5491", city: "Blommenslyst"},
    {zip: "5492", city: "Vissenbjerg"},
    {zip: "5500", city: "Middelfart"},
    {zip: "5540", city: "Ullerslev"},
    {zip: "5550", city: "Langeskov"},
    {zip: "5560", city: "Aarup"},
    {zip: "5580", city: "Nørre Aaby"},
    {zip: "5591", city: "Gelsted"},
    {zip: "5592", city: "Ejby"},
    {zip: "5600", city: "Faaborg"},
    {zip: "5601", city: "Lyø"},
    {zip: "5602", city: "Avernakø"},
    {zip: "5603", city: "Bjørnø"},
    {zip: "5610", city: "Assens"},
    {zip: "5620", city: "Glamsbjerg"},
    {zip: "5631", city: "Ebberup"},
    {zip: "5642", city: "Millinge"},
    {zip: "5672", city: "Broby"},
    {zip: "5683", city: "Haarby"},
    {zip: "5690", city: "Tommerup"},
    {zip: "5700", city: "Svendborg"},
    {zip: "5750", city: "Ringe"},
    {zip: "5762", city: "Vester Skerninge"},
    {zip: "5771", city: "Stenstrup"},
    {zip: "5772", city: "Kværndrup"},
    {zip: "5792", city: "Årslev"},
    {zip: "5800", city: "Nyborg"},
    {zip: "5853", city: "Ørbæk"},
    {zip: "5854", city: "Gislev"},
    {zip: "5856", city: "Ryslinge"},
    {zip: "5863", city: "Ferritslev Fyn"},
    {zip: "5871", city: "Frørup"},
    {zip: "5874", city: "Hesselager"},
    {zip: "5881", city: "Skårup Fyn"},
    {zip: "5882", city: "Vejstrup"},
    {zip: "5883", city: "Oure"},
    {zip: "5884", city: "Gudme"},
    {zip: "5892", city: "Gudbjerg Sydfyn"},
    {zip: "5900", city: "Rudkøbing"},
    {zip: "5932", city: "Humble"},
    {zip: "5935", city: "Bagenkop"},
    {zip: "5943", city: "Strynø"},
    {zip: "5953", city: "Tranekær"},
    {zip: "5960", city: "Marstal"},
    {zip: "5965", city: "Birkholm"},
    {zip: "5970", city: "Ærøskøbing"},
    {zip: "5985", city: "Søby Ærø"},
    {zip: "6000", city: "Kolding"},
    {zip: "6040", city: "Egtved"},
    {zip: "6051", city: "Almind"},
    {zip: "6052", city: "Viuf"},
    {zip: "6064", city: "Jordrup"},
    {zip: "6070", city: "Christiansfeld"},
    {zip: "6091", city: "Bjert"},
    {zip: "6092", city: "Sønder Stenderup"},
    {zip: "6093", city: "Sjølund"},
    {zip: "6094", city: "Hejls"},
    {zip: "6100", city: "Haderslev"},
    {zip: "6200", city: "Aabenraa"},
    {zip: "6210", city: "Barsø"},
    {zip: "6230", city: "Rødekro"},
    {zip: "6240", city: "Løgumkloster"},
    {zip: "6261", city: "Bredebro"},
    {zip: "6270", city: "Tønder"},
    {zip: "6280", city: "Højer"},
    {zip: "6300", city: "Gråsten"},
    {zip: "6310", city: "Broager"},
    {zip: "6320", city: "Egernsund"},
    {zip: "6330", city: "Padborg"},
    {zip: "6340", city: "Kruså"},
    {zip: "6360", city: "Tinglev"},
    {zip: "6372", city: "Bylderup-Bov"},
    {zip: "6392", city: "Bolderslev"},
    {zip: "6400", city: "Sønderborg"},
    {zip: "6430", city: "Nordborg"},
    {zip: "6440", city: "Augustenborg"},
    {zip: "6470", city: "Sydals"},
    {zip: "6500", city: "Vojens"},
    {zip: "6510", city: "Gram"},
    {zip: "6520", city: "Toftlund"},
    {zip: "6534", city: "Agerskov"},
    {zip: "6535", city: "Branderup J"},
    {zip: "6541", city: "Bevtoft"},
    {zip: "6560", city: "Sommersted"},
    {zip: "6580", city: "Vamdrup"},
    {zip: "6600", city: "Vejen"},
    {zip: "6621", city: "Gesten"},
    {zip: "6622", city: "Bække"},
    {zip: "6623", city: "Vorbasse"},
    {zip: "6630", city: "Rødding"},
    {zip: "6640", city: "Lunderskov"},
    {zip: "6650", city: "Brørup"},
    {zip: "6660", city: "Lintrup"},
    {zip: "6670", city: "Holsted"},
    {zip: "6682", city: "Hovborg"},
    {zip: "6683", city: "Føvling"},
    {zip: "6690", city: "Gørding"},
    {zip: "6700", city: "Esbjerg"},
    {zip: "6701", city: "Esbjerg"},
    {zip: "6705", city: "Esbjerg Ø"},
    {zip: "6710", city: "Esbjerg V"},
    {zip: "6715", city: "Esbjerg N"},
    {zip: "6720", city: "Fanø"},
    {zip: "6731", city: "Tjæreborg"},
    {zip: "6740", city: "Bramming"},
    {zip: "6752", city: "Glejbjerg"},
    {zip: "6753", city: "Agerbæk"},
    {zip: "6760", city: "Ribe"},
    {zip: "6771", city: "Gredstedbro"},
    {zip: "6780", city: "Skærbæk"},
    {zip: "6792", city: "Rømø"},
    {zip: "6800", city: "Varde"},
    {zip: "6818", city: "Årre"},
    {zip: "6823", city: "Ansager"},
    {zip: "6830", city: "Nørre Nebel"},
    {zip: "6840", city: "Oksbøl"},
    {zip: "6851", city: "Janderup Vestj"},
    {zip: "6852", city: "Billum"},
    {zip: "6853", city: "Vejers Strand"},
    {zip: "6854", city: "Henne"},
    {zip: "6855", city: "Outrup"},
    {zip: "6857", city: "Blåvand"},
    {zip: "6862", city: "Tistrup"},
    {zip: "6870", city: "Ølgod"},
    {zip: "6880", city: "Tarm"},
    {zip: "6893", city: "Hemmet"},
    {zip: "6900", city: "Skjern"},
    {zip: "6920", city: "Videbæk"},
    {zip: "6933", city: "Kibæk"},
    {zip: "6940", city: "Lem St"},
    {zip: "6950", city: "Ringkøbing"},
    {zip: "6960", city: "Hvide Sande"},
    {zip: "6971", city: "Spjald"},
    {zip: "6973", city: "Ørnhøj"},
    {zip: "6980", city: "Tim"},
    {zip: "6990", city: "Ulfborg"},
    {zip: "7000", city: "Fredericia"},
    {zip: "7007", city: "Fredericia"},
    {zip: "7017", city: "Taulov Pakkecenter"},
    {zip: "7018", city: "Pakker TLP"},
    {zip: "7029", city: "Fredericia"},
    {zip: "7080", city: "Børkop"},
    {zip: "7100", city: "Vejle"},
    {zip: "7120", city: "Vejle Øst"},
    {zip: "7130", city: "Juelsminde"},
    {zip: "7140", city: "Stouby"},
    {zip: "7150", city: "Barrit"},
    {zip: "7160", city: "Tørring"},
    {zip: "7171", city: "Uldum"},
    {zip: "7173", city: "Vonge"},
    {zip: "7182", city: "Bredsten"},
    {zip: "7183", city: "Randbøl"},
    {zip: "7184", city: "Vandel"},
    {zip: "7190", city: "Billund"},
    {zip: "7200", city: "Grindsted"},
    {zip: "7250", city: "Hejnsvig"},
    {zip: "7260", city: "Sønder Omme"},
    {zip: "7270", city: "Stakroge"},
    {zip: "7280", city: "Sønder Felding"},
    {zip: "7300", city: "Jelling"},
    {zip: "7321", city: "Gadbjerg"},
    {zip: "7323", city: "Give"},
    {zip: "7330", city: "Brande"},
    {zip: "7361", city: "Ejstrupholm"},
    {zip: "7362", city: "Hampen"},
    {zip: "7400", city: "Herning"},
    {zip: "7429", city: "Herning"},
    {zip: "7430", city: "Ikast"},
    {zip: "7441", city: "Bording"},
    {zip: "7442", city: "Engesvang"},
    {zip: "7451", city: "Sunds"},
    {zip: "7470", city: "Karup J"},
    {zip: "7480", city: "Vildbjerg"},
    {zip: "7490", city: "Aulum"},
    {zip: "7500", city: "Holstebro"},
    {zip: "7540", city: "Haderup"},
    {zip: "7550", city: "Sørvad"},
    {zip: "7560", city: "Hjerm"},
    {zip: "7570", city: "Vemb"},
    {zip: "7600", city: "Struer"},
    {zip: "7620", city: "Lemvig"},
    {zip: "7650", city: "Bøvlingbjerg"},
    {zip: "7660", city: "Bækmarksbro"},
    {zip: "7673", city: "Harboøre"},
    {zip: "7680", city: "Thyborøn"},
    {zip: "7700", city: "Thisted"},
    {zip: "7730", city: "Hanstholm"},
    {zip: "7741", city: "Frøstrup"},
    {zip: "7742", city: "Vesløs"},
    {zip: "7752", city: "Snedsted"},
    {zip: "7755", city: "Bedsted Thy"},
    {zip: "7760", city: "Hurup Thy"},
    {zip: "7770", city: "Vestervig"},
    {zip: "7790", city: "Thyholm"},
    {zip: "7800", city: "Skive"},
    {zip: "7830", city: "Vinderup"},
    {zip: "7840", city: "Højslev"},
    {zip: "7850", city: "Stoholm Jyll"},
    {zip: "7860", city: "Spøttrup"},
    {zip: "7870", city: "Roslev"},
    {zip: "7884", city: "Fur"},
    {zip: "7900", city: "Nykøbing M"},
    {zip: "7950", city: "Erslev"},
    {zip: "7960", city: "Karby"},
    {zip: "7970", city: "Redsted M"},
    {zip: "7980", city: "Vils"},
    {zip: "7990", city: "Øster Assels"},
    {zip: "7992", city: "Sydjylland/Fyn USF P"},
    {zip: "7993", city: "Sydjylland/Fyn USF B"},
    {zip: "7996", city: "Fakturaservice"},
    {zip: "7997", city: "Fakturascanning"},
    {zip: "7998", city: "Statsservice"},
    {zip: "7999", city: "Kommunepost"},
    {zip: "8000", city: "Aarhus C"},
    {zip: "8100", city: "Aarhus C"},
    {zip: "8200", city: "Aarhus N"},
    {zip: "8210", city: "Aarhus V"},
    {zip: "8220", city: "Brabrand"},
    {zip: "8229", city: "Risskov Ø"},
    {zip: "8230", city: "Åbyhøj"},
    {zip: "8240", city: "Risskov"},
    {zip: "8245", city: "Risskov Ø"},
    {zip: "8250", city: "Egå"},
    {zip: "8260", city: "Viby J"},
    {zip: "8270", city: "Højbjerg"},
    {zip: "8300", city: "Odder"},
    {zip: "8305", city: "Samsø"},
    {zip: "8310", city: "Tranbjerg J"},
    {zip: "8320", city: "Mårslet"},
    {zip: "8330", city: "Beder"},
    {zip: "8340", city: "Malling"},
    {zip: "8350", city: "Hundslund"},
    {zip: "8355", city: "Solbjerg"},
    {zip: "8361", city: "Hasselager"},
    {zip: "8362", city: "Hørning"},
    {zip: "8370", city: "Hadsten"},
    {zip: "8380", city: "Trige"},
    {zip: "8381", city: "Tilst"},
    {zip: "8382", city: "Hinnerup"},
    {zip: "8400", city: "Ebeltoft"},
    {zip: "8410", city: "Rønde"},
    {zip: "8420", city: "Knebel"},
    {zip: "8444", city: "Balle"},
    {zip: "8450", city: "Hammel"},
    {zip: "8462", city: "Harlev J"},
    {zip: "8464", city: "Galten"},
    {zip: "8471", city: "Sabro"},
    {zip: "8472", city: "Sporup"},
    {zip: "8500", city: "Grenaa"},
    {zip: "8520", city: "Lystrup"},
    {zip: "8530", city: "Hjortshøj"},
    {zip: "8541", city: "Skødstrup"},
    {zip: "8543", city: "Hornslet"},
    {zip: "8544", city: "Mørke"},
    {zip: "8550", city: "Ryomgård"},
    {zip: "8560", city: "Kolind"},
    {zip: "8570", city: "Trustrup"},
    {zip: "8581", city: "Nimtofte"},
    {zip: "8585", city: "Glesborg"},
    {zip: "8586", city: "Ørum Djurs"},
    {zip: "8592", city: "Anholt"},
    {zip: "8600", city: "Silkeborg"},
    {zip: "8620", city: "Kjellerup"},
    {zip: "8632", city: "Lemming"},
    {zip: "8641", city: "Sorring"},
    {zip: "8643", city: "Ans By"},
    {zip: "8653", city: "Them"},
    {zip: "8654", city: "Bryrup"},
    {zip: "8660", city: "Skanderborg"},
    {zip: "8670", city: "Låsby"},
    {zip: "8680", city: "Ry"},
    {zip: "8700", city: "Horsens"},
    {zip: "8721", city: "Daugård"},
    {zip: "8722", city: "Hedensted"},
    {zip: "8723", city: "Løsning"},
    {zip: "8732", city: "Hovedgård"},
    {zip: "8740", city: "Brædstrup"},
    {zip: "8751", city: "Gedved"},
    {zip: "8752", city: "Østbirk"},
    {zip: "8762", city: "Flemming"},
    {zip: "8763", city: "Rask Mølle"},
    {zip: "8765", city: "Klovborg"},
    {zip: "8766", city: "Nørre Snede"},
    {zip: "8781", city: "Stenderup"},
    {zip: "8783", city: "Hornsyld"},
    {zip: "8789", city: "Endelave"},
    {zip: "8799", city: "Tunø"},
    {zip: "8800", city: "Viborg"},
    {zip: "8830", city: "Tjele"},
    {zip: "8831", city: "Løgstrup"},
    {zip: "8832", city: "Skals"},
    {zip: "8840", city: "Rødkærsbro"},
    {zip: "8850", city: "Bjerringbro"},
    {zip: "8860", city: "Ulstrup"},
    {zip: "8870", city: "Langå"},
    {zip: "8881", city: "Thorsø"},
    {zip: "8882", city: "Fårvang"},
    {zip: "8883", city: "Gjern"},
    {zip: "8900", city: "Randers C"},
    {zip: "8920", city: "Randers NV"},
    {zip: "8930", city: "Randers NØ"},
    {zip: "8940", city: "Randers SV"},
    {zip: "8950", city: "Ørsted"},
    {zip: "8960", city: "Randers SØ"},
    {zip: "8961", city: "Allingåbro"},
    {zip: "8963", city: "Auning"},
    {zip: "8970", city: "Havndal"},
    {zip: "8981", city: "Spentrup"},
    {zip: "8983", city: "Gjerlev J"},
    {zip: "8990", city: "Fårup"},
    {zip: "9000", city: "Aalborg"},
    {zip: "9029", city: "Aalborg"},
    {zip: "9100", city: "Aalborg"},
    {zip: "9200", city: "Aalborg SV"},
    {zip: "9210", city: "Aalborg SØ"},
    {zip: "9220", city: "Aalborg Øst"},
    {zip: "9230", city: "Svenstrup J"},
    {zip: "9240", city: "Nibe"},
    {zip: "9260", city: "Gistrup"},
    {zip: "9270", city: "Klarup"},
    {zip: "9280", city: "Storvorde"},
    {zip: "9293", city: "Kongerslev"},
    {zip: "9300", city: "Sæby"},
    {zip: "9310", city: "Vodskov"},
    {zip: "9320", city: "Hjallerup"},
    {zip: "9330", city: "Dronninglund"},
    {zip: "9340", city: "Asaa"},
    {zip: "9352", city: "Dybvad"},
    {zip: "9362", city: "Gandrup"},
    {zip: "9370", city: "Hals"},
    {zip: "9380", city: "Vestbjerg"},
    {zip: "9381", city: "Sulsted"},
    {zip: "9382", city: "Tylstrup"},
    {zip: "9400", city: "Nørresundby"},
    {zip: "9430", city: "Vadum"},
    {zip: "9440", city: "Aabybro"},
    {zip: "9460", city: "Brovst"},
    {zip: "9480", city: "Løkken"},
    {zip: "9490", city: "Pandrup"},
    {zip: "9492", city: "Blokhus"},
    {zip: "9493", city: "Saltum"},
    {zip: "9500", city: "Hobro"},
    {zip: "9510", city: "Arden"},
    {zip: "9520", city: "Skørping"},
    {zip: "9530", city: "Støvring"},
    {zip: "9541", city: "Suldrup"},
    {zip: "9550", city: "Mariager"},
    {zip: "9560", city: "Hadsund"},
    {zip: "9574", city: "Bælum"},
    {zip: "9575", city: "Terndrup"},
    {zip: "9600", city: "Aars"},
    {zip: "9610", city: "Nørager"},
    {zip: "9620", city: "Aalestrup"},
    {zip: "9631", city: "Gedsted"},
    {zip: "9632", city: "Møldrup"},
    {zip: "9640", city: "Farsø"},
    {zip: "9670", city: "Løgstør"},
    {zip: "9681", city: "Ranum"},
    {zip: "9690", city: "Fjerritslev"},
    {zip: "9700", city: "Brønderslev"},
    {zip: "9740", city: "Jerslev J"},
    {zip: "9750", city: "Østervrå"},
    {zip: "9760", city: "Vrå"},
    {zip: "9800", city: "Hjørring"},
    {zip: "9830", city: "Tårs"},
    {zip: "9850", city: "Hirtshals"},
    {zip: "9870", city: "Sindal"},
    {zip: "9881", city: "Bindslev"},
    {zip: "9900", city: "Frederikshavn"},
    {zip: "9940", city: "Læsø"},
    {zip: "9970", city: "Strandby"},
    {zip: "9981", city: "Jerup"},
    {zip: "9982", city: "Ålbæk"},
    {zip: "9990", city: "Skagen"},

  ]

  useEffect(() => {
    (async () => {
      let c = 0;
      let tmps = [];
      
      setList(ziplist);
    })();
  }, []);

  logEvent(analytics, "zipoverview");
  return (
    <>
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Grid item xs={6} lg={6}>
        <Paper className={classes.paper}>
          <Typography variant="h5" color="textPrimary" align="left">
            Postnummer: {zip}
          </Typography>
          <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                  {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => { handleRequestSort(headCell.id)}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list && stableSort(list, getComparator(order, orderBy)).map((row) => (
                    <TableRow key={row.no}>
                      <TableCell component={Link}to={"/zip/" + row.zip} target="_blank">{row.zip}</TableCell>
                      <TableCell align='left' padding='normal'>{row.city && row.city}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </Paper>
      </Grid>
      </Grid>
    </>
  );
}


export default Zipoverview;