import React, { useEffect, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, TableSortLabel, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import getGlobalStyles from './globalStyles';
import { initializeApp } from "firebase/app";
import { useParams } from "react-router-dom";
import { getFirestore, connectFirestoreEmulator, collection, query, getDocs, where } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Link } from 'react-router-dom';
import { visuallyHidden } from '@mui/utils';

const firebaseConfig = {
  apiKey: "AIzaSyABSrvkQzmv_4q8KD26qH8GHB0OnDootr8",
  authDomain: "regnskabsdata.firebaseapp.com",
  projectId: "regnskabsdata",
  storageBucket: "regnskabsdata.appspot.com",
  messagingSenderId: "685976971439",
  appId: "1:685976971439:web:86fb6d10c79982d97d0c5a",
  measurementId: "G-PV0JXDMPJH"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

function Zip() {
  
  const [loading, setLoading] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = React.useState([]);
  const [list, setList] = useState([]);
  let { zip } = useParams();

  const useLocalStyles = makeStyles((theme) => ({
    ...getGlobalStyles(theme),
    table: {
      minWidth: 500,
    }
  }));

  const classes = useLocalStyles();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[0] - b[0];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Firma',
    },
    {
      id: 'assets',
      numeric: true,
      disablePadding: true,
      label: 'Balance',
    },
    {
      id: 'equity',
      numeric: true,
      disablePadding: true,
      label: 'Egenkapital',
    },
    {
      id: 'tax',
      numeric: true,
      disablePadding: true,
      label: 'Skat',
    },
    {
      id: 'result',
      numeric: true,
      disablePadding: true,
      label: 'Resultat efter skat',
    },
  ]

  useEffect(() => {
    (async () => {
      let c = 0;
      let tmps = [];
      const q = query(collection(db, "cvr"), where("zip", "==", Number(zip)));
      const snap = await getDocs(q);
      snap.forEach((doc) => {
        let data = doc.data();
        let tmp = Object();
        tmp.name = data.name;
        tmp.cvr = doc.id;
        tmp.tax = data.finance && data.finance.tax;
        tmp.result = data.finance && data.finance.result;
        tmp.assets = data.finance && data.finance.assets;
        tmp.equity = data.finance && data.finance.equity;
        tmp.no = c++;
        tmps.push(tmp);
      })
      setList(tmps);
  
    })();
  }, []);

  logEvent(analytics, "zip");
  return (
    <>
      <Grid item xs={12} lg={12}>
        <Paper className={classes.paper}>
          <Typography variant="h5" color="textPrimary" align="left">
            Postnummer: {zip}
          </Typography>
          <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table" >
                <TableHead>
                  <TableRow>
                  {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => { handleRequestSort(headCell.id)}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list && stableSort(list, getComparator(order, orderBy)).map((row) => (
                    <TableRow key={row.no}>
                      <TableCell component={Link}to={"/cvr/" + row.cvr} target="_blank">{row.name}</TableCell>
                      <TableCell align='right' padding='none'>{row.assets && row.assets.toLocaleString()}</TableCell>
                      <TableCell align='right' padding='none'>{row.equity && row.equity.toLocaleString()}</TableCell>
                      <TableCell align='right' padding='none'>{row.tax && row.tax.toLocaleString()}</TableCell>
                      <TableCell align='right' padding='none'>{row.result && row.result.toLocaleString()}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </Paper>
      </Grid>
    </>
  );
}


export default Zip;