import React, { useEffect, useState  } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { initializeApp } from "firebase/app";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import getGlobalStyles from './globalStyles';
import AdSense from 'react-adsense';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { get_address, get_bygning } from './dawa';
import { get } from 'lodash';

const firebaseConfig = {
    apiKey: "AIzaSyABSrvkQzmv_4q8KD26qH8GHB0OnDootr8",
    authDomain: "regnskabsdata.firebaseapp.com",
    projectId: "regnskabsdata",
    storageBucket: "regnskabsdata.appspot.com",
    messagingSenderId: "685976971439",
    appId: "1:685976971439:web:86fb6d10c79982d97d0c5a",
    measurementId: "G-PV0JXDMPJH"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
//const functions = getFunctions(app);

function CvrInfo() {

    let { cvr } = useParams();
    logEvent(analytics, "cvrinfo", { cvr: cvr });

    const useLocalStyles = makeStyles((theme) => ({
        ...getGlobalStyles(theme),
        chips: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        paper100: {
            height: '100%',
        }

    }));

    const classes = useLocalStyles();

    const [cvrdata, setcvrdata] = useState(Object());
    const [cvrdoc, setcvrdoc] = useState(Object());
    const [loading, setloading] = useState(0);
    const [map, setMap] = useState(null)
    const [dawa, setdawa] = useState(Object());
    const [bygning, setbygning] = useState(Object());
    const [center, setcenter] = useState(Object());

    const containerStyle = {
        width: '350px',
        height: '300px'
    };


    useEffect(() => {

        async function get_cvrdata() {
            const docRef = doc(db, "cvrdata", cvr);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = await docSnap.data();
                return data.data;
                console.log("Document data:", data.data);
                await setcvrdata(data.data);
            }
        }

        async function get_dawa(id) {
            const adr1 = await get_address(id);
            setdawa(adr1);
            console.log(adr1);
            setcenter({ lat: adr1.adgangsadresse.adgangspunkt.koordinater[1], lng: adr1.adgangsadresse.adgangspunkt.koordinater[0] });
            const tmp = await get_bygning(adr1.adgangsadresse.id);
            console.log(tmp);
            setbygning(tmp);
        }

        async function get_cvr() {
            const docRef1 = doc(db, "cvr", cvr);
            const docSnap1 = await getDoc(docRef1);
            if (docSnap1.exists()) {
                const data = await docSnap1.data();
                return data;
                await setcvrdoc(data);
                console.log("cvr", data);
            }

        }

        let res1 = get_cvrdata();
        let res2 = get_cvr();
        Promise.all([res1, res2])
            .then((values) => {
                console.log(values);
                setcvrdata(values[0]);
                setcvrdoc(values[1]);
                console.log(values[0].nyesteBeliggenhedsadresse.adresseId);
                setloading(1);
                // get_dawa(values[0].nyesteBeliggenhedsadresse.adresseId)
                //     .then(() => {
                //         setloading(1);
                //     })
            })
            .catch((err) => {
                throw err;

            })

    }, []);

    return (
        <>
            {loading === 1 &&
                <>
                    <Grid item xs={12} md={6} xl={4}>
                        <Paper className={[classes.paper, classes.paper100]}>
                            <Typography variant="h5" color="textPrimary" align="left">CVR registeret</Typography>
                            <div className={classes.chips}>
                                <CreateItem name={get(cvrdata, "nyesteNavn.navn", "Ukendt")} />
                                <CreateItem name={cvrdata.nyesteBeliggenhedsadresse.vejnavn + " " + cvrdata.nyesteBeliggenhedsadresse.husnummerFra + " " + cvrdata.nyesteBeliggenhedsadresse.postnummer + " " + cvrdata.nyesteBeliggenhedsadresse.postdistrikt} />
                                <CreateItem name={get(cvrdata, "sammensatStatus", "Ukendt")} />
                                <CreateItem name={"Stiftelsesdato: " + get(cvrdata, "stiftelsesDato", "Ukendt")} />
                                <CreateItem name={get(cvrdata, "nyesteHovedbranche.branchetekst", "Ukendt")} />
                                <CreateItem name={"Ansatte: " + get(cvrdata, "nyesteMaanedsbeskaeftigelse.antalAnsatte", "Ukendt")} />

                                {cvrdata.nyesteKontaktoplysninger.map(x => <CreateItem name={"Kontakt: " + x} />)}
                                {cvrdata.nyesteBinavne.map(x => <CreateItem name={"Binavn: " + x} />)}
                            </div>
                        </Paper>
                    </Grid>
                    {/* <Grid item xs={12} md={6} xl={4}>
                        <Paper className={[classes.paper, classes.paper100]}>
                            <LoadScript googleMapsApiKey="AIzaSyABSrvkQzmv_4q8KD26qH8GHB0OnDootr8">
                                <div align="center">
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={center}
                                        zoom={15}
                                    >
                                    </GoogleMap>
                                </div>
                            </LoadScript>

                        </Paper>
                    </Grid> */}
                    <Grid item xs={12} md={6} xl={4}>
                        <Paper className={[classes.paper, classes.paper100]}>
                            <Typography variant="h5" color="textPrimary" align="left">Økonomi</Typography>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table" className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nøgletal</TableCell>
                                            <TableCell align="right">kr</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Skat</TableCell>
                                            <TableCell align="right">{cvrdoc.finance && cvrdoc.finance.tax.toLocaleString()}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Resultat</TableCell>
                                            <TableCell align="right">{cvrdoc.finance && cvrdoc.finance.result.toLocaleString()}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Egenkapital</TableCell>
                                            <TableCell align="right">{cvrdoc.finance && cvrdoc.finance.equity.toLocaleString()}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Balance</TableCell>
                                            <TableCell align="right">{cvrdoc.finance && cvrdoc.finance.assets.toLocaleString()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    {/* <Grid item xs={12} md={6} xl={4}>
                        <Paper className={[classes.paper, classes.paper100]}>
                            <Typography variant="h5" color="textPrimary" align="left">Oplysninger om adressen</Typography>
                            <div className={classes.chips}>
                                <CreateItem name={"Brofast: " + (dawa.adgangsadresse.brofast ? "Ja" : "Nej")} />
                                <CreateItem name={"Højde over havets overflade: " + (dawa.adgangsadresse.adgangspunkt.højde) + " meter"} />
                                <CreateItem name={"Matrikel: " + (dawa.adgangsadresse.matrikelnr)} />
                                <CreateItem name={"Retskreds: " + (dawa.adgangsadresse.retskreds.navn)} />
                                <CreateItem name={"Politikreds: " + (dawa.adgangsadresse.politikreds.navn)} />
                                <CreateItem name={"Zone: " + (dawa.adgangsadresse.zone)} />
                                <CreateItem name={"Varmeinstallation: " + get(bygning, "VARMEINSTAL_KODE", "Ukendt")} />
                                <CreateItem name={"Opvarmningskilde: " + get(bygning, "OPVARMNING_KODE", "Ukendt")} />
                                <CreateItem name={"Etager: " + get(bygning, "ETAGER_ANT", "Ukendt")} />
                            </div>
                        </Paper>
                    </Grid> */}
                </>
            }
        </>
    );


}

function CreateItem(props) {
    return (
        <Chip color="primary" label={props.name} />

    );
}

export default CvrInfo;