import { get } from 'lodash';
const axios = require('axios');


const baseurl = "https://dawa.aws.dk/adresser?";
const baseurl_1 = "https://dawa.aws.dk/adresser/";
const baseurl_2 = "https://api.dataforsyningen.dk/bbrlight/opgange?adgangsadresseid=";
const baseurl_3 = "https://api.dataforsyningen.dk/bbrlight/bygninger?id=";

const varmeinst = {
	0: "Ukendt",
	1: "Fjernvarme/blokvarme",
	2: "Centralvarme fra eget anlæg, et-kammer fyr",
	3: "Ovne (kakkelovne, kamin, brændeovn o.lign.)",
	5: "Varmepumpe",
	6: "Centralvarme med to fyringsenheder",
	7: "Elovne, elpaneler",
	8: "Gasradiatorer",
	9: "Ingen varmeinstallationer",
	99: "Blandet"
};

const opvarmning = {
	0: "Ukendt",
	1: "Elektricitet",
	2: "Gasværksgas",
	3: "Flydende brændsel (olie, petroleum, flaskegas)",
	4: "Fast brændsel (kul, koks, brænde m.m.)",
	6: "Halm",
	7: "Naturgas",
	9: "Andet"
};

const supl_varme = [
	"Ukendt",
	"Varmepumpeanlæg",
	"Ovne til fast brændsel (brændeovn o.lign.)",
	"Ovne til flydende brændsel",
	"Solpaneler",
	"Pejs",
	"Gasradiatorer",
	"Elovne, elpaneler",
	"Biogasanlæg",
	"Andet",
	"Bygningen har ingen supplerende varme"
];


export async function get_address(id) {
	console.log(baseurl_1 + id)
	let res = await axios.get(baseurl_1 + id);
	return res.data;
}

export async function get_bygning(aid) {
	console.log(aid);
	let res = await axios.get(baseurl_2 + aid);
	console.log(res.data);
	let bid = res.data[0].Bygning_id;
	let res2 = await axios.get(baseurl_3 + bid);
	let data = res2.data[0];
	if (data.VARMEINSTAL_KODE) data.VARMEINSTAL_KODE = get(varmeinst, data.VARMEINSTAL_KODE, "Ukendt");
	if (data.OPVARMNING_KODE) data.OPVARMNING_KODE = get(opvarmning, data.OPVARMNING_KODE, "Ukendt");
	return data;
}