import React, { useEffect, useState } from 'react';
import { Typography, FormControl, InputLabel, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, IconButton, Container, ButtonGroup, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AllMunicipalities } from './lists';
import getGlobalStyles from './globalStyles';
import clsx from 'clsx';
import ClipLoader from "react-spinners/ClipLoader";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Link } from 'react-router-dom';
import { get } from 'lodash';

const firebaseConfig = {
  apiKey: "AIzaSyABSrvkQzmv_4q8KD26qH8GHB0OnDootr8",
  authDomain: "regnskabsdata.firebaseapp.com",
  projectId: "regnskabsdata",
  storageBucket: "regnskabsdata.appspot.com",
  messagingSenderId: "685976971439",
  appId: "1:685976971439:web:86fb6d10c79982d97d0c5a",
  measurementId: "G-PV0JXDMPJH"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);

function Top10() {

  const [loading, setLoading] = useState(0);

  const useLocalStyles = makeStyles((theme) => ({
    ...getGlobalStyles(theme),
    table: {
      minWidth: 500,
    }
  }));

  const classes = useLocalStyles();
  const [equity, setEquity] = useState([]);
  const [assets, setAssets] = useState([]);
  const [tax, setTax] = useState([]);
  const [result, setReult] = useState([]);

  useEffect(() => {
    (async () => {

      let top10 = await (await getDoc(doc(db, "sums", "top10"))).data();
      console.log(top10.equity);
      setEquity(top10.equity);
      setTax(top10.tax);
      setAssets(top10.assets);
      setReult(top10.result);
    })();
  }, []);

  logEvent(analytics, "Top10");
  return (
    <>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography variant="h5" color="textPrimary" align="left">
            Egenkapital
          </Typography>
          <ShowTable rows={equity}/>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography variant="h5" color="textPrimary" align="left">
            Balance
          </Typography>
          <ShowTable rows={assets} />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography variant="h5" color="textPrimary" align="left">
            Skat
          </Typography>
          <ShowTable rows={tax}/>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <Typography variant="h5" color="textPrimary" align="left">
            Resultat efter skat
          </Typography>
          <ShowTable rows={result} />
        </Paper>
      </Grid>

    </>
  );
}

function ShowTable(props) {
  return (
    <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table" >
                <TableHead>
                  <TableRow>
                    <TableCell>Nummer</TableCell>
                    <TableCell>Firma</TableCell>
                    <TableCell align="right">Test</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.rows && props.rows.map((row) => (
                    <TableRow key={row.no}>
                      <TableCell component="th" scope="row">
                        {row.no}
                      </TableCell>
                      <TableCell component={Link}to={"/cvr/" + row.cvr} target="_blank">{row.name}</TableCell>
                      <TableCell align="right">{row.val.toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
  )
}


export default Top10;