import React, { useEffect, useState, useStyles } from 'react';
import clsx from 'clsx';
import { AppBar, Toolbar, Menu, MenuItem, Typography, Button, FormControl, InputLabel, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, withStyles, CssBaseline, Grid, Box, Divider, Badge, IconButton, Container, Drawer, List } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import { AllMunicipalities } from './lists';
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { mainListItems, secondaryListItems } from './listitems';
import Top100 from './Top100';
import Top10 from './Top10';
import getGlobalStyles from './globalStyles';
import AdSense from 'react-adsense';
import CvrInfo from './cvrinfo';
import Zip from './Zip';
import Zipoverview from './zipoverview';

const firebaseConfig = {
  apiKey: "AIzaSyABSrvkQzmv_4q8KD26qH8GHB0OnDootr8",
  authDomain: "regnskabsdata.firebaseapp.com",
  projectId: "regnskabsdata",
  storageBucket: "regnskabsdata.appspot.com",
  messagingSenderId: "685976971439",
  appId: "1:685976971439:web:86fb6d10c79982d97d0c5a",
  measurementId: "G-PV0JXDMPJH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);

console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV == 'developmen') {
connectFunctionsEmulator(functions, "localhost", 5001);
connectFirestoreEmulator(db, 'localhost', 8080);
}

function App() {

  const useLocalStyles = makeStyles((theme) => ({
    ...getGlobalStyles(theme)
  }));



  const [anchorMainMenu, setAnchorMainMenu] = useState(null);
  const classes = useLocalStyles();
  const [rows, setRows] = useState([]);
  const [keyvalue, setKeyvalue] = useState("");

  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Firmainfo
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{mainListItems}</List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Switch>
                <Route path="/top100" component={Top100} />
                <Route path="/top10" component={Top10} />
                <Route path="/zip/:zip" component={Zip} />
                <Route path="/zip" component={Zipoverview} />
                <Route path="/cvr/:cvr" component={CvrInfo} />
                
                <Route path="/">
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    <Typography variant="h5" color="textPrimary" align="left">Velkommen til firmainfo!</Typography>
                      <Typography variant="body1" color="textPrimary" align="left">                        
                        Her er samlet forskellige offentlige data om firmaer (ApS, A/S og K/S). Du kan se top 100 lister pr. kommune ud fra forskellige nøgletal, eller nationale top 10 lister.
                        For hvert enkelt firma kan du også se informationer fra DAR som fx. højde over havets overflade, opvarmingsform eller om bygningen er fredet.
                      </Typography>
                    </Paper>
                  </Grid>
                </Route>
              </Switch>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography variant="body2" color="textSecondary" align="center">
                    Brug af data på eget ansvar. Copyright © Mitec consulting ApS - misak (at) mitec-consulting (dot) dk
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Box pt={4}>

            </Box>
          </Container>
        </main>
      </div>
    </Router>



  );
}

export default App;
