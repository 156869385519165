import React, { useEffect, useState } from 'react';
import { Typography, FormControl, InputLabel, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, IconButton, Container, ButtonGroup, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AllMunicipalities } from './lists';
import getGlobalStyles from './globalStyles';
import clsx from 'clsx';
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyABSrvkQzmv_4q8KD26qH8GHB0OnDootr8",
  authDomain: "regnskabsdata.firebaseapp.com",
  projectId: "regnskabsdata",
  storageBucket: "regnskabsdata.appspot.com",
  messagingSenderId: "685976971439",
  appId: "1:685976971439:web:86fb6d10c79982d97d0c5a",
  measurementId: "G-PV0JXDMPJH"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function Top100() {

  const [m, setM] = useState("");
  const [rows, setRows] = useState([]);
  const [keyvalue, setKeyvalue] = useState("");
  const [loading, setLoading] = useState(0);




  const useLocalStyles = makeStyles((theme) => ({
    ...getGlobalStyles(theme),
    table: {
      minWidth: 500,
    }
  }));

  const classes = useLocalStyles();

  useEffect(() => {
    console.log(keyvalue);
    if (keyvalue !== '' && m !== '') {
      setLoading(1);

      var url = 'https://us-central1-regnskabsdata.cloudfunctions.net/top100?municipality=' + m + '&key=' + keyvalue + '&system=finance';
      //var url = 'http://127.0.0.1:5001/regnskabsdata/us-central1/top100?municipality=' + m + '&key=' + keyvalue + '&system=finance';
      
      (async () => {
        console.log(url);
        try {
        const x1 = await fetch(url, { method: 'GET', mode: 'cors' });
        console.log(x1.status);
        if (x1.ok) {
          if (x1.status == 204) {
            console.log("No data");
            setLoading(3);
          } else {
            console.log("Data");
            setRows(await x1.json());
            setLoading(2);
          }
        }
      } catch (error) {
        setLoading(4);
      }
      

      })();

    }
  }, [keyvalue, m])


  logEvent(analytics, "Top100");

  return (
    <>
      <Grid item xs={12} lg={12}>
        <Paper className={classes.paper}>
          <Typography variant="body1" color="textPrimary" align="left">
            Her kan du se top 100 lister over firmaer i en bestemt kommune sorteret efter et af følgende nøgletal: Egenkapital, Balance, Skat, Resultat efter skat.
            <br />
            Der er taget udgangspunkt i det seneste registrerede årsregnskab i XBRL format.
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          <FormControl>
            <InputLabel htmlFor="kommune-native-simple">Kommune</InputLabel>
            <Select
              native
              value={m}
              onChange={(event) => setM(event.target.value)}
              inputProps={{
                name: 'kommune',
                id: 'kommune-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              <AllMunicipalities />
            </Select>
          </FormControl>
          <div className={classes.buttongroup}>
            <ButtonGroup size="small" color="primary" variant="contained" color="primary">
              <Button onClick={(event) => setKeyvalue('equity')}>Egenkapital</Button>
              <Button onClick={(event) => setKeyvalue('assets')}>Balance</Button>
              <Button onClick={(event) => setKeyvalue('tax')}>Skat</Button>
              <Button onClick={(event) => setKeyvalue('result')}>Resultat</Button>
            </ButtonGroup>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.paper}>
          {loading == 0 && <Typography variant="h5" color="textSecondary" align="center">Vælg venligst<br /> søgekriterier</Typography>}
          {loading == 1 && <center><ClipLoader loading={loading === 1} size={100} /></center>}
          {loading == 2 &&
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Nummer</TableCell>
                    <TableCell>Firma</TableCell>
                    <TableCell align="right">{keyvalue}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.no}>
                      <TableCell component="th" scope="row">
                        {row.no}
                      </TableCell>
                      <TableCell component={Link} to={"/cvr/" + row.cvr} target="_blank">{row.name}</TableCell>
                      <TableCell align="right">{row.val.toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          {loading == 3 && <Typography variant="h5" color="textSecondary" align="center">Data for denne kommune er ved at blive læst ind.<br />Prøv igen på senere tidspunkt.</Typography>}
          {loading == 4 && <Typography variant="h5" color="textSecondary" align="center">Der er sket en fejl.<br />Prøv igen på senere tidspunkt.</Typography>}
        </Paper>
      </Grid>
    </>
  )
}

export default Top100;