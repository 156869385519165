
import React from 'react';
export function AllMunicipalities() {
    
    return (
        <>
            <option value={165}>Albertslund</option>
            <option value={201}>Allerød</option>
            <option value={420}>Assens</option>
            <option value={151}>Ballerup</option>
            <option value={530}>Billund</option>
            <option value={400}>Bornholm</option>
            <option value={153}>Brøndby</option>
            <option value={810}>Brønderslev</option>
            <option value={411}>Christiansø</option>
            <option value={155}>Dragør</option>
            <option value={240}>Egedal</option>
            <option value={561}>Esbjerg</option>
            <option value={563}>Fanø</option>
            <option value={710}>Favrskov</option>
            <option value={320}>Faxe</option>
            <option value={210}>Fredensborg</option>
            <option value={607}>Fredericia</option>
            <option value={147}>Frederiksberg</option>
            <option value={813}>Frederikshavn</option>
            <option value={250}>Frederikssund</option>
            <option value={190}>Furesø</option>
            <option value={430}>Faaborg-Midtfyn</option>
            <option value={157}>Gentofte</option>
            <option value={159}>Gladsaxe</option>
            <option value={270}>Gribskov</option>
            <option value={161}>Glostrup</option>
            <option value={253}>Greve</option>
            <option value={376}>Guldborgsund</option>
            <option value={766}>Hedensted</option>
            <option value={510}>Haderslev</option>
            <option value={217}>Helsingør</option>
            <option value={260}>Halsnæs</option>  
            <option value={163}>Herlev</option>
            <option value={657}>Herning</option>
            <option value={219}>Hillerød</option>
            <option value={860}>Hjørring</option>
            <option value={316}>Holbæk</option>
            <option value={661}>Holstebro</option>
            <option value={615}>Horsens</option>
            <option value={167}>Hvidovre</option>
            <option value={169}>Høje-Taastrup</option>
            <option value={223}>Hørsholm</option>
            <option value={756}>Ikast-Brande</option>
            <option value={183}>Ishøj</option>
            <option value={849}>Jammerbugt</option>
            <option value={326}>Kalundborg</option>
            <option value={440}>Kerteminde</option>
            <option value={621}>Kolding</option>
            <option value={101}>København</option>
            <option value={259}>Køge</option>
            <option value={482}>Langeland</option>
            <option value={350}>Lejre</option>
            <option value={665}>Lemvig</option>
            <option value={360}>Lolland</option>
            <option value={173}>Lyngby-Taarbæk</option>
            <option value={825}>Læsø</option>
            <option value={846}>Mariagerfjord</option>
            <option value={410}>Middelfart</option>
            <option value={773}>Morsø</option>
            <option value={707}>Norddjurs</option>
            <option value={480}>Nordfyns</option>
            <option value={450}>Nyborg</option>
            <option value={370}>Næstved</option>
            <option value={727}>Odder</option>
            <option value={461}>Odense</option>
            <option value={306}>Odsherred</option>
            <option value={329}>Ringsted</option>
            <option value={230}>Rudersdal</option>
            <option value={175}>Rødovre</option>
            <option value={265}>Roskilde</option>
            <option value={730}>Randers</option>
            <option value={840}>Rebild</option>
            <option value={760}>Ringkøbing-Skjern</option>
            <option value={741}>Samsø</option>
            <option value={740}>Silkeborg</option>
            <option value={671}>Struer</option>
            <option value={746}>Skanderborg</option>
            <option value={330}>Slagelse</option>
            <option value={779}>Skive</option>
            <option value={269}>Solrød</option>
            <option value={340}>Sorø</option>
            <option value={336}>Stevns</option>
            <option value={479}>Svendborg</option>
            <option value={706}>Syddjurs</option>
            <option value={540}>Sønderborg</option>
            <option value={787}>Thisted</option>
            <option value={550}>Tønder</option>
            <option value={185}>Tårnby</option>
            <option value={187}>Vallensbæk</option>
            <option value={573}>Varde</option>
            <option value={575}>Vejen</option>
            <option value={630}>Vejle</option>
            <option value={820}>Vesthimmerlands</option>
            <option value={791}>Viborg</option>
            <option value={390}>Vordingborg</option>
            <option value={492}>Ærø</option> 
            <option value={580}>Aabenraa</option>
            <option value={851}>Aalborg</option>
            <option value={751}>Aarhus</option>
        </> 
    )
}